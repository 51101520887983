export const Additional = () => {
  return (
    <div className="additional">
      <p className="additional__title">Дополнительна информация</p>
      <div className="additional__wrapper additional__wrapper--mobile">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Обновлено</p>
            <p className="additional__description">03.12.2024</p>
          </div>

          <div className="additional__item">
            <p className="additional__text">Установлено</p>
            <p className="additional__description">127544</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Возраст</p>
            <p className="additional__description">18+</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Размер</p>
            <p className="additional__description">3.7MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Текущая версия</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Разработчик</p>
            <p className="additional__description">100 Super Hot</p>
          </div>
        </div>
      </div>
      <div className="additional__wrapper additional__wrapper--desktop">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Обновлено</p>
            <p className="additional__description">03.12.2024</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Размер</p>
            <p className="additional__description">3.7MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Установлено</p>
            <p className="additional__description">127544</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Текущая версия</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Возраст</p>
            <p className="additional__description">18+</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Разработчик</p>
            <p className="additional__description">100 Super Hot</p>
          </div>
        </div>
      </div>
    </div>
  );
};
